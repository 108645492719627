@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.MyAccountOrderPopup {
    .Popup-Content {
        @include desktop {
            min-width: 810px;
        }
    }

    &-BaseInfo,
    &-PaymentInfo,
    &-ShippingWrapper,
    &-ProductsWrapper,
    &-OrderWrapper {
        h4 {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: var(--table-font-size);
            line-height: 150%;
            color: $grey7;
            margin-bottom: 13px;
        }
    }
    &-BaseInfo,
    &-PaymentInfo,
    &-ShippingWrapper {
        margin-top: 15px;

        dt,
        dd {
            color: $default-primary-base-color;
            font-weight: 500;
            font-family: $font-secondary;
            font-size: var(--table-font-size);
            line-height: 150%;
        }

        dd {
            color: $black;
            font-weight: normal;
        }
    }

    &-BaseInfo {
        margin-top: 30px;
    }

    &-PaymentLinkWrapper {
        margin-top: 15px;
    }

    &-Address.MyAccountAddressTable {
        margin: 60px 0 15px;

        tbody tr {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 6px;
        }
        tbody th,
        tbody td {
            font-size: var(--table-font-size);
            line-height: 150%;
            letter-spacing: 0.05em;
        }
        tbody th {
            width: 100%;
            padding-right: 0;
        }
        tbody td {
            width: 100%;
            padding-left: 0;
        }
    }

    &-ProductsWrapper {
        .MyAccountOrderPopup-Products {
            thead th {
                border-radius: 0;

                @include mobile {
                    white-space: nowrap;
                }

                &:first-of-type {
                    border-radius: var(--table-head-radius) 0 0 var(--table-head-radius);
                }
                &:last-of-type {
                    border-radius: 0 var(--table-head-radius) var(--table-head-radius) 0;
                }
            }
            tbody tr:last-child {
                border-bottom: var(--table-body-border);
            }

            tbody tr td:first-of-type {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 150%;
                color: $default-primary-base-color;
                font-weight: bold;
                font-family: $font-primary;
                letter-spacing: 0.05em;
                > div {
                    margin-right: 10px;
                    min-width: 60px;

                    @include from-tablet {
                        margin-right: 25px;
                    }
                }
            }

            tbody tr td:nth-of-type(2) {
                font-weight: normal;
                font-size: 13px;
                line-height: 150%;
                color: $grey3;
            }

            tbody tr td:nth-of-type(3) {
                letter-spacing: -0.2px;
                color: $black;
                font-weight: bold;
                font-size: var(--table-font-size);
                line-height: 26px;
                white-space: nowrap;
            }

            thead tr th:nth-of-type(2),
            tbody tr td:nth-of-type(2) {
                text-align: center;
            }

            thead tr th:nth-of-type(3),
            tbody tr td:nth-of-type(3) {
                text-align: right;
            }
        }
    }

    &-OrderWrapper {
        margin-top: 15px;
        dt,
        dd {
            color: $default-primary-base-color;
            font-weight: 500;
            font-family: $font-secondary;
            font-size: var(--table-font-size);
            line-height: 150%;
        }

        dd {
            color: $black;
            font-weight: normal;
        }
    }

    &-Button {
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        color: $black;
        margin-bottom: 30px;

        .Icon {
            &-Reorder {
                height: 16px;
                width: 15px;

                svg,
                path {
                    fill: $default-primary-base-color;
                }
            }
        }

        [class*='Icon-'] {
            margin-right: 12px;
        }
    }
}
