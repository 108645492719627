@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.MyAccountCreditsHistory {
    &-Customer {
        margin-block-end: 32px;
        padding-inline: 24px;
        padding-block: 27px 24px;
        font-size: 16px;
        border: 1px solid $grey40;

        @include desktop {
            margin-block-end: 40px;
            padding-inline: 30px 24px;
            padding-block: 28px 32px;
        }

        > span {
            font-weight: var(--secondary-font-weight-bold);
        }
    }

    &-Wrapper {
        @include mobile {
            margin-inline: calc(var(--content-padding) * -1);
            max-width: 100vw;

            & > .MyAccountCreditsHistory-Table {
                margin-inline: var(--content-padding);
            }
        }
    }

    &-Table {
        width: 100%;
        margin-block-end: 0;
        table-layout: auto;

        th {
            white-space: nowrap;
        }
    }

    &-PageSize {
        display: flex;
        align-items: center;

        @include mobile {
            margin-top: 30px;
        }

        label {
            font-size: 13px;
        }

        .Field {
            margin-top: 0;
            margin-left: 10px;

            &Select {
                min-width: unset !important;
            }
        }
    }

    &-Pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;

        @include mobile {
            flex-direction: column-reverse;
            align-items: center;
        }

        ul {
            margin: 0;
        }
    }
}
